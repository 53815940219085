<template>
  <v-container>
    <h1 class="heading">debug page</h1>
    <v-checkbox v-model="isHostModified" label="ホスト名の変更を有効にする" />
    <fieldset class="debug-settings">
      <v-text-field v-model="modifiedHostName" hide-details :disabled="!isHostModified" label="ホスト名" />
      <v-btn color="primary" @click="applyHost">反映する</v-btn>
    </fieldset>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from 'nuxt-property-decorator';
import { Context } from '@nuxt/types';
import rison from 'rison';
import { FeatureSettings, FEATURE_TOGGLE_DEBUG_STORAGE_KEY, MODIFIED_HOST_DEBUG_STORAGE_KEY } from '@/utils/debugUtils';

type AsyncData = Pick<Debug, 'isFeatureToggleEnable' | 'features' | 'isHostModified' | 'modifiedHostName'>;

const settingDefault: { isFeatureToggleEnable: boolean; features: FeatureSettings } = Object.freeze({
  isFeatureToggleEnable: false,
  features: {},
});

const hostDefault = Object.freeze({
  isHostModified: false,
  modifiedHostName: '',
});

@Component
export default class Debug extends Vue {
  isFeatureToggleEnable!: boolean;
  features!: FeatureSettings;
  isHostModified!: boolean;
  modifiedHostName!: string;

  applySettings(): void {
    if (!this.$sessionStorage) return;
    if (this.isFeatureToggleEnable && this.features) {
      const payload = rison.encode_object<FeatureSettings>(this.features);
      this.$sessionStorage.setItem(FEATURE_TOGGLE_DEBUG_STORAGE_KEY, payload);
    } else {
      this.$sessionStorage.removeItem(FEATURE_TOGGLE_DEBUG_STORAGE_KEY);
    }
    location.reload();
  }

  applyHost(): void {
    if (!this.$sessionStorage) return;
    if (this.isHostModified && this.modifiedHostName !== '') {
      this.$sessionStorage.setItem(MODIFIED_HOST_DEBUG_STORAGE_KEY, this.modifiedHostName);
    } else {
      this.$sessionStorage.removeItem(MODIFIED_HOST_DEBUG_STORAGE_KEY);
    }
    location.reload();
  }

  async asyncData({ app: { $sessionStorage }, error }: Context): Promise<AsyncData> {
    if (process.env.NODE_ENV !== 'development') error({ message: 'This page could not be found', statusCode: 404 });

    if ($sessionStorage) {
      const settings = $sessionStorage.getItem(FEATURE_TOGGLE_DEBUG_STORAGE_KEY);
      const settingObject =
        settings !== null
          ? {
              isFeatureToggleEnable: true,
              features: rison.decode_object<FeatureSettings>(settings),
            }
          : settingDefault;
      const host = $sessionStorage.getItem(MODIFIED_HOST_DEBUG_STORAGE_KEY);
      const hostObject =
        host !== null
          ? {
              isHostModified: true,
              modifiedHostName: host,
            }
          : hostDefault;
      return { ...settingObject, ...hostObject };
    }
    return { ...settingDefault, ...hostDefault };
  }
}
</script>

<style lang="scss" scoped>
.debug-settings {
  border: 0;
  padding-left: 2em;
  margin-bottom: 4em;
}
</style>
